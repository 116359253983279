@font-face {
  font-family: 'GothamBook';
  font-style: normal;
  font-weight: normal;
  src: local('GothamBook'), url('../fonts/GothamBook.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light Italic'), url('../fonts/GothamMedium.woff') format('woff');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/fontsfree-net-sfprodisplay-regular-webfont.woff2') format('woff2'),
    url('../fonts/fontsfree-net-sfprodisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

div.MuiSelect-root {
  background-image: url('../images/down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  height: 24px;
  border: none;
  background-color: #f7f7f7;
  padding-top: 16px;
}

.video-input-select .MuiInputBase-root fieldset {
  border: none;
}

svg.MuiSelect-icon {
  display: none;
}

.MuiSnackbar-root {
  z-index: 999999 !important;
}

.react-chat-viewerBox {
  max-height: 300px !important;
}

.message-list {
  overflow-y: auto;
  padding: 0 24px;
  height: calc(100vh - 101px);
}

.message-list ul {
  list-style: none;
  padding: 0;
  margin: 16px 0;
}

.message-list ul li {
  padding-bottom: 27px;
  background: unset;
  margin-bottom: 15px;
  padding: 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.message-list ul li .msg-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 8px;
}

.message-list ul li .msg-auth {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: capitalize;
  padding-right: 0;
  font-family: 'roboto';
  min-width: 35px;
  min-height: 35px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 5px;
  padding-bottom: 2px;
}

.message-list ul li .msg-bottom {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 8px;
  align-items: center;
}

.message-list ul li .msg-bottom .msg-username-full {
  font-size: 12px;
  margin-right: 8px;
  color: #b1b0b0;
}

.message-list ul li .message-item {
  width: calc(100% - 120px);
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.01);
}

.message-list ul li .msg-time {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b1b0b0;
}

.message-list ul li .msg-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373737;
  width: 100%;
  overflow: hidden;
  word-break: break-all;
}

.message-list ul li .msg-text p {
  margin: 0;
}

.message-list ul li.self-message .msg-info {
  justify-content: flex-end;
}

.message-list ul li.self-message .msg-info .msg-auth {
  order: 2;
  padding-right: 0;
  margin-right: 0;
  margin-left: 5px;
  background: #000;
}

.message-list ul li.self-message .message-item {
  background: #000000;
}

.message-list ul li.self-message .message-item a {
  color: #fff;
  text-decoration: underline;
}

.message-list ul li .message-item a {
  text-decoration: underline;
}

.message-list ul li.self-message .msg-text {
  color: #ffffff;
}

.chat-form {
  background: #fff;
  /* margin: 24px -24px; */
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  box-shadow: 0 -2px 9px 0 rgba(194, 194, 194, 0.5);
  padding-left: 15px;
}

.chat-form input {
  border-radius: 15.5px;
  border: solid 1px #dedede;
  height: 31px;
  padding: 0 10px;
  width: 203px;
}

.chat-form fieldset {
  border: none;
}

.chat-form .send-btn {
  border: none;
  background: transparent;
  margin-left: 0;
}

.chat-form>div:nth-child(1) {
  margin: 10px 0;
}

.active-user-onMeeting {
  width: 125px !important;
  display: flex !important;
  align-items: center;
  border: 2px solid #fff;
}

.active-user-onMeeting img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.active-user-onMeeting span {
  max-width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

 

.newMsgBadge {
  position: absolute;
  top: -5px;
  right: -0px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newMsgBadge span {
  border-radius: 100%;
  background-color: red;
  color: white;
  width: 10px;
  height: 10px;
  display: flex;
}

.drawer-tabs-container>a {
  display: flex;
  height: 47px;
  font-size: 15px;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: capitalize;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.drawer-tabs-container>a>span {
  flex-direction: row;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  filter: invert(44%) sepia(0%) saturate(0%) hue-rotate(7deg) brightness(98%) contrast(91%);
}

.drawer-tabs-container>a>span svg,
.drawer-tabs-container>a>span img {
  margin-right: 13px;
}

.drawer-tabs-container>a.active>span {
  color: #4caf50;
  filter: invert(30%) sepia(79%) saturate(421%) hue-rotate(44deg) brightness(104%) contrast(88%);
  height: 100%;
  border-bottom: 2px solid#4CAF50;
  -webkit-filter: invert(30%) sepia(79%) saturate(421%) hue-rotate(44deg) brightness(104%) contrast(88%);
}

.nav-tabs {
  border-bottom: 2px solid #b5b5b5 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f2f2f2 !important;
  background: #f2f2f2;
  border: none !important;
}

:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  outline-width: 0 !important;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .message-list {
    height: calc(65vh - 101px);
  }

  .drawer-tabs-container>a>span svg,
  .drawer-tabs-container>a>span img {
    margin-right: 8px;
  }

  /* .option-btns {
        opacity: 1 !important;
        visibility: visible !important;
    } */
  .option-btns .MuiFab-root:last-child {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1145px) {
  .left-side-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-side-content {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .sidebarEnabled {
    width: calc(100% - 320px) !important;
  }
}

/* Paper drawer sidebar */
.drawer-paper-sidebar .MuiPaper-root {
  background: #f9f9f9 !important;
  border: 0 !important;
}

.drawer-paper-sidebar .nav-tabs {
  border: 0 !important;
  padding: 0;
}

.drawer-paper-sidebar .tab-content {
  padding: 10px;
}

.drawer-paper-sidebar .nav-tabs .nav-item {
  border-radius: 0;
  background: #fff;
  border: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.drawer-paper-sidebar .nav-tabs .nav-item span {
  filter: grayscale(1);
  border: none;
}

.drawer-paper-sidebar .nav-tabs .nav-item.active span {
  filter: unset;
  -webkit-filter: unset;
  color: #fff;
}

.drawer-paper-sidebar .nav-tabs .nav-item.active {
  background-color: #000 !important;
}

.drawer-paper-sidebar .nav-tabs .nav-item:hover {
  border: none;
}

.drawer-paper-sidebar .message-list {
  padding: 0 0;
  height: calc(100vh - 111px);
}

.drawer-paper-sidebar .chat-form {
  margin: 0;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  margin-left: -10px;
  background: #fff;
  padding-top: 2px;
  padding-left: 10px;
}

.drawer-paper-sidebar .nav-tabs .nav-item.active svg {
  fill: #fff;
}

.drawer-paper-sidebar .nav-tabs .nav-item:first-child {
  border-bottom-left-radius: 5px;
}

.drawer-paper-sidebar .nav-tabs .nav-item:last-child {
  border-bottom-right-radius: 5px;
}

.drawer-paper-sidebar .tab-content video {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.drawer-paper-sidebar .send-btn .MuiButton-label {
  background: #4caf50;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
}

.drawer-paper-sidebar .send-btn .MuiButton-label svg {
  fill: #fff;
  transform: rotate(-28deg);
  position: relative;
  left: 2px;
  bottom: 1px;
}

@media (max-width: 991px) {
  .drawer-paper-sidebar .MuiPaper-root {
    height: 95% !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .drawer-paper-sidebar .message-list {
    height: calc(100vh - 110px)!important;
  }
  
  .chat-form>div:first-child {
      margin-top: 0 !important;
  }
  .chat-form .send-btn {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
 
  .drawer-paper-sidebar {
    height: 100%;
    bottom: 0;
  }

  .drawer-paper-sidebar .tab-content {
    padding: 10px 10px 0 10px;
    height: calc(100% - 47px)
  }

  .drawer-paper-sidebar .tab-content>.tab-pane:nth-child(2).active {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    height: 100%;
    padding: 0px 0;
  }

  .drawer-paper-sidebar .message-list {
    padding: 0 !important;
    height: unset !important;
  }

  .drawer-paper-sidebar .message-list ul {
    margin: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .drawer-paper-sidebar .chat-form {
    position: unset;
  }

  .drawer-paper-sidebar .chat-form {
    width: calc(100% + 20px);
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    background: #fff;
    padding-top: 2px;
    padding-left: 10px;
    position: relative;
    left: -10px;
    padding-bottom: 0;
    height: 60px;
    bottom: 0;
    align-items: center; 
  }
  .drawer-paper-sidebar .message-list {
    height: calc(100% - 50px) !important;
  }
  .message-list ul li.self-message {
    margin-right: 5px;
  }
}

.participants-wrapper {
  position: absolute;
  top: 65px;
  right: 30px;
  height: calc(100vh - 100px);
  width: 200px;
  z-index: 9;
}
.participants-wrapper .participant-item {
  border-radius: 10px;
}
.participants-wrapper .participant-item .participant-tools {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}
.participants-wrapper .participant-item .participant-tools h4 {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  max-width: 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background:#4c4c4c;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
}
.participants-wrapper .participant-item .participant-tools .screenshare {
  top: 40px;
}
 
.participants-wrapper .participant-item .participant-tools .video-off {
  left: unset;
  right: 10px;
}
.participants-wrapper .participant-item .participant-tools .network-quality {
  left: unset;
  right: 10px;
}
.participants-wrapper .participant-item .participant-tools h4 + div {
  display: none;
}
.participants-wrapper .participant-item .participant-view {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
}
.participants-wrapper .participant-item .no-photo-wrapper {
  background-color: #fff;
}
.logo-wrapper {
  justify-content: center;
}
.logo-wrapper img {
  width: 200px;
}

@media (max-width: 991px){
  .logo-wrapper img  {
    width: 100px;
  }
}

/*Quill restyle*/
.ql-toolbar.ql-snow {
  display: none;
}

.quill {
  width: calc(100% - 65px) !important;
  margin: 0 !important;
  height: 43px;
  margin-top: 5px !important;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.ql-container.ql-snow {
  border-radius: 15px;
  border: 0;
}

.ql-editor {
  padding: 10px 15px;
}
